@import '../../Styles/settings';

.root {
  padding-top: $mobile-header-height;
  padding-bottom: $verticleBlockPaddingXS;
  background-color: $light-gray; //TEMP
}

.h1 {
  max-width: 800px;
  width: 100%;
  font-size: 36px;
  margin-left: auto;
  padding-right: 91px;
}

.banner {
  padding: 32px 15px 64px 15px;
  position: relative;
}

.header {
  flex-wrap: wrap-reverse;
}

.bannerLigth {
  background-color: $clear-blue;
  color: $color-bona-blue;
}

.bannerDark {
  background-color: $darkmidnight_blue;
  color: white;
}

.logoContainer {
  display: flex;
  margin-bottom: 23px;
}

.logoContainerTop {
  top: 0;
  right: 0;
  position: absolute;
}

.logo {
  margin: auto;
  width: 100%;
  max-width: max-content;
}

.logoSmall {
  height: 56px;
  width: 56px;
}

.div {
  max-width: 800px;
  margin: 0 auto;
}

.contentWrapper {
  margin-bottom: 8px;
}

.tabContainer {
  position: absolute;
  z-index: 1;
  bottom: -1px;
  gap: 4px;
  display: flex;
}
.priceSpiderContainer {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.btn {
  @include HelveticaMedium;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  padding: 16px 12px;
  background: $light-gray;
  color: $near-black;
  border-width: 0;
  border-radius: 4px 4px 0 0;

  &.selected {
    background: $bona-light-blue;
    color: $color-brand;
  }
}

@media #{$md-up} {
  .root {
    padding-top: $standard-header-height-md-up;
    padding-bottom: unset;
  }

  .btn {
    padding: 16px;
    font-size: 18px;
    line-height: 24px;
  }

  .header {
    flex-wrap: unset;
  }

  .logoContainer {
    position: relative;
    margin-bottom: unset;
    height: 100%;
    width: 100%;
  }

  .h1 {
    padding-right: unset;
  }

  .logo {
    margin: auto;
    margin-right: 0;
  }

  .banner {
    padding-top: 64px;
  }
}

@include media($content-container-width) {
  .contentWrapper {
    max-width: 1158px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}
