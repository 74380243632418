@import '../../Styles/settings';

.retailerLogo, .retailerLogoAction {
  background: $white;
  box-shadow: 0px 4px 20px rgba(95, 99, 104, 0.08);
  min-height: 100%;
  border-radius: 4px;
  display: flex;
  overflow: hidden;
}

.retailerLogo {
  width: 75px;
  justify-content: center;
  align-items: center;
  height: 64px;
  overflow: hidden;
}

.retailerLogoAction {
  width: 75px;
  flex-direction: column;  
}

.image {
  width: 100%;
  height: auto;
}

.imgContainer {
  display: flex;
  max-height: calc(100% - 8px);
  margin: 8px;
}

.retailerLogo {
  border: 1px solid transparent;
  &:hover {
    border-color: $light-cyan;
  }
}

.btn {
  @include HelveticaMedium;
  font-size: 12px;
  background-color: $midnight-blue;
  color: $white;
  height: 32px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  &:hover {
    background-color: $darkmidnight_blue;
  }
}


@media #{$sm-up} {

  .retailerLogo, .retailerLogoAction {
    width: 168px;
    height: 149px;
  }
}