@import '../../Styles/settings';

.span {
  display: block;
  line-height: 24px;
  font-size: 16px;
  color: $darkest-gray;
  font-style: normal;
}

.heading {
  @include HelveticaMedium;
  margin-bottom: 5px;
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: $midnight-blue;
}

.root {
  margin-bottom: 16px;
  padding: 16px;
  background-color: white;
  cursor: pointer;
  border-radius: 8px;
  width: 100%;
  min-height: 210px;
  display: flex;
  flex-direction: column;
  border: 1px solid $white;
  position: relative;
  &:hover {
    border-color: $alto;
  }
}

.onMap {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-bottom: unset;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 100;
  right: 0;
  left: 0;
}

.topRight {
  position: absolute;
  top: 0;
  right: 0;
  flex-direction: column;
  a {
    margin-bottom: 12px;
  }
}

.contactLocation {
  display: flex;
  margin-top: 16px;

  & .contact:last-child {
    margin-right: 0;
  }
}

.contact,
.location {
  @include HelveticaMedium;
  display: flex;
  margin-right: 16px;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
  color: $color-brand;

  svg {
    width: 32px;
    height: 32px;
    background-color: $clear-blue;
    border-radius: 50%;
    padding: 8px;
    overflow: visible;
    margin-right: 8px;
  }
}

.locationOnMap, .contactOnMap {
  margin-right: 8px;
}

.cardFooter {
  display: flex;
  margin-top: auto;
  flex-direction: column;
  margin-top: 16px;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.category {
  line-height: 16px;
  padding: 4px 8px;
  color: $darkest-gray;
  background-color: $light-gray;
  border-radius: 20px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.link {
  @include HelveticaMedium;
  font-size: 14px;
  line-height: 16px;
}

.linkWrapper {
  display: flex;
  align-items: flex-end;
  right: 16px;
  position: absolute;
  bottom: 16px;
  margin-top: unset;
}

.baseButton {
  color: $midnight-blue;
  border: none;
  align-items: center;
  display: flex;
  background-color: transparent;
  padding: 8px 0px;
  text-decoration: none;
  letter-spacing: 0px;
  cursor: pointer;
  position: relative;
  z-index: map-get($zIndexes, 'tabsBtn');
  transition: border-top 0.5s $main-timing-function;
  font-size: 16px;
  line-height: 24px;

  svg {
    margin-left: auto;
    height: 16px;
    width: 16px;
    transition: transform 0.2s $main-timing-function;
    flex-shrink: 0;
  }

  span {
    font-size: 14px;
    line-height: 16px;
    margin-right: 8px;
  }

  &.selected {
    svg {
      transform: rotate(180deg);
    }
  }
}

.slideDownOuter {
  max-height: 0;
  transition: max-height 0.4s ease-out;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  
  & p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
  }
}

.slideDownInner {
  max-height: 193px;
  overflow: hidden;
  transition: max-height 0.4s ease-in;
  transition-delay: 0.1s;
}

@media #{$sm-up} {
  .span,
  .a {
    font-size: 14px;
  }

  .root {
    &:after {
      background-size: auto 150%;
      background-position: right -20px top 0px;
    }
  }

  .cardFocus {
    border-color: $light-blue;

    &:hover {
      border-color: $light-blue;
    }
  }
}

@media #{$lg-up} {
  .cardFooter {    
    margin-top: 24px;
  }
  
}
