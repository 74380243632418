@import '../../../Styles/settings';

.category,
.categorySelected {
  @include HelveticaMedium;
  border: 1px solid $color-brand;
  box-sizing: border-box;
  color: $color-brand;
  border-radius: 20px;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 16px;
  margin-right: 8px;
  display: flex;

  svg {
    margin-right: 4px;
    margin-left: 0;
  }

  &:hover {
    background-color: $color-brand;
    color: $white;
    cursor: pointer;

    svg {
      &:first-of-type {
        display: none;
      }
      &:last-of-type {
        display: inline;
      }
    }
  }
}

.categorySelected {
    background-color: $bona-dark-blue;
    color: $white;
    svg {
      path {
        stroke: $white;
      }
    }
    &:hover {
      background-color: $near-black;
    }
  }

  .categoryCross {
    display: none;
  }

  @media #{$lg-up} {
  .category,
  .categorySelected {
    margin-bottom: 8px;
    width: fit-content;
  }
}