@import '../../Styles/settings';

.root {
  display: flex;
  flex-direction: column;
}

.inputContainer {
  margin-bottom: 24px;
  overflow-x: auto;
  /* width */
  &::-webkit-scrollbar {
    display: none;
  }
}

.categoryWrapper {
  display: flex;
  width: max-content;
}

.searchInputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  svg {
    margin-right: 8px;
  }
}

.inputWrapper {
  position: relative;
  flex: 1;
  margin-bottom: 16px;
}

.inputIcon {
  position: absolute;
  right: 16px;
  margin-right: unset;
  top: 14px;
  cursor: pointer;
}

.input {
  @include text-input;
  border-color: $alto;
  padding-right: 46px;
  flex: 1;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $darkest-gray;
    letter-spacing: 0;
  }
}

.categoryTitle {
  @include HelveticaMedium;
  font-size: 10px;
  line-height: 12px;
  color: $darkest-gray;
  width: 100%;
  padding: 16px 0;
  text-transform: uppercase;
}

//Tabs
.mobileTab {
  display: flex;
  border-radius: 4px;
  border: 1px solid $color-brand;
  margin-bottom: 32px;
}

.hidden {
  position: fixed !important;
  left: 99999px;
}

.paddingTop {
  padding-top: 64px;
}

.tab {
  color: $color-brand;
  width: 50%;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.tabActive {
  background-color: $color-brand;
  color: $white;
  svg {
    path {
      stroke: $white;
    }
  }
}

.mapOptions {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}

.map {
  width: 100%;
  height: 500px;
  &:empty {
    display: none;
  }
}

.markersList {
  margin-top: 20px;
  &:empty {
    display: none;
  }
}

.mapIcon {
  fill: currentColor;
  width: 80px;
  height: 75.78px;
}

.cookieContainer {
  max-width: 100%;
  flex: 0 0 100%;
}

.cookieInfo {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 15px;
  background: white;
}

.cookieInfoTextWrapper {
  flex: 0 0 100%;
  max-width: 100%;
}

.cookieInfoBtnWrapper {
  flex: 0 0 100%;
  justify-content: center;
  display: flex;
}

.iconWrapper {
  position: relative;
  height: 80px;
  width: 80px;
  margin-bottom: 10px;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
        to top left,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) calc(50% - 2px),
        $color-error 50%,
        rgba(0, 0, 0, 0) calc(50% + 2px),
        rgba(0, 0, 0, 0) 100%
      ),
      linear-gradient(
        to top right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) calc(50% - 2px),
        $color-error 50%,
        rgba(0, 0, 0, 0) calc(50% + 2px),
        rgba(0, 0, 0, 0) 100%
      );
  }
}
// needs to use the element in the selector otherwise the global
// styling will be override
p.notFoundText {
  @include HelveticaMedium;
  margin: 20px 0 0;
  display: flex;
  width: auto;
  padding: 0px;
}

.gradient {
  font-size: 16px;
  text-align: center;
  margin-bottom: 24px;
  margin: 45px 55px 16px 55px;
}

.gradientText {
  margin-bottom: 16px;
}

.gradientBar {
  height: 8px;
  border-radius: 4px;
}

@media #{$sm-up} {
  .mapOptions {
    margin-bottom: 0;
  }

  .searchInputContainer {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .input {
    margin-bottom: unset;
  }

  .inputWrapper {
    margin-right: 8px;
    margin-bottom: unset;
  }
}

@media #{$lg-up} {
  .map {
    height: 1085px;
  }

  .root {
    min-height: 1085px;
    flex-direction: row;
    overflow-y: clip;
  }

  .mapOptionsWrapper {
    width: 50%;
    padding-right: 8px;
    max-height: 1085px;
  }

  .mapOptions {
    overflow-y: hidden;
  }

  .map {
    width: 50%;
    position: relative;
    overflow: visible !important;

    > div {
      width: 50vw !important;
      overflow: visible;

      > div {
        overflow: hidden;
      }
    }
  }

  .inputContainer {
    overflow-x: auto;
    padding-right: 16px;
  }

  .categoryWrapper {
    justify-content: flex-start;
    flex-wrap: wrap;
    width: unset;
  }

  .searchInputContainer {
    padding-right: 16px;

    svg {
      margin-right: 8px;
    }
  }

  .btn {
    height: 40px;
  }

  .categoryTitle {
    padding-right: 16px;
  }

  .markersList {
    max-width: calc(100% + 15px);
    max-height: 840px;
    flex: 0 0 calc(100% + 15px);
    margin-top: 30px;
    padding-right: 8px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    &:empty {
      display: flex;
    }
  }

  .cardWrapper {
    width: 100%;
    box-shadow: 0px 4px 20px titn($darkest-gray, 10%);
  }

  .showMoreCardsBtn {
    font-weight: 400;
    background: $midnight-blue;
    transition: background-color 0.3s $main-timing-function;
    cursor: pointer;
    &:hover {
      background-color: darken($midnight-blue, 8%);
    }
  }
}
