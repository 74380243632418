@import '../../Styles/settings';

.root {
  width: 100%;
  padding: 56px 0;
}

.content{
  margin-top: 48px;
}

.onlineStoreCardGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

h4.header {
  margin-top: 16px;
}

@media #{$sm-up} {
  .root {
    padding-top: 72px;
    flex-direction: row;
  }
  .checkboxWrapper {
    width: auto;
  }
}

@media #{$md-up} {
  .root {
    min-height: 900px;
  }

  .onlineStoreCardGrid {
    gap: 24px;
  }
  

  .inputContainer,
  .checkboxContainer {
    max-width: 100%;
    flex: 0 0 50%;
    justify-content: flex-start;
  }

  .content{
    margin-top: 72px;
  }
}